import { Link } from 'gatsby';
import styled from 'styled-components';
import { ContainerColumnStartWithoutWidth } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { blueIcon, containerWidth, darkGreen, greyBorder } from '../../styles/Variables';

export const Container = styled(ContainerColumnStartWithoutWidth)`
    width: ${containerWidth};
    margin: 0 auto;

    @media ${deviceMax.tabletM} {
        width: calc(100% - 30px);
    }
`;

export const WrapperHeadline = styled.h3`
    font-size: 0.9375rem;
    letter-spacing: 0.025em;
    margin: 4.5rem 0 6px 0;
    text-transform: uppercase;
`;

export const WrapperText = styled.p`
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    margin-bottom: 1.25rem;
    padding: 0;
`;

export const MapWrapper = styled.div`
    width: 100%;
    height: 400px;
`;

export const MarkerBox = styled.div``;

export const PopupText = styled.p`
    text-align: center;
    color: ${blueIcon};
    cursor: pointer;
`;

export const ContactsWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

export const PersonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 3.5rem;
`;

export const PersonTitle = styled(Link)`
    text-transform: uppercase;
    color: ${blueIcon};
    font-size: 1.125rem;
    font-weight: 700;
    margin: 10px 0 6px 0;
    padding: 0;
`;

export const PersonContentBox = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    height: 80px;
    border-top: 1px solid ${greyBorder};
    border-bottom: 1px solid ${greyBorder};
`;

export const PersonImage = styled.img`
    width: 100%;
    height: 80px;
`;

export const PersonContactDetails = styled.div`
    display: flex;
    flex-direction: column;

    * {
        margin: 4px 0 4px 20px;
        padding: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.875rem;
    }
`;

export const PersonName = styled.h3``;

export const PersonPhone = styled.a`
    padding-left: 20px;
    margin: 2px 0 2px 20px;
`;

export const PersonEmail = styled(PersonPhone)`
    color: ${darkGreen};
`;
